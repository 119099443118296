<script setup lang="ts">
import type MenuItem from "@/components/models/MenuItem";
import LanguageSelection from "@/components/LanguageSelection.vue";
import useAuthStore from "@/stores/auth";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import Role, { canEditAnyEntry } from "@/stores/interfaces/Role";
import { useRoute } from "vue-router";
import type { LocationQueryRaw, RouteRecordName } from "vue-router";

const auth = useAuthStore();
const i18n = useI18n();
const route = useRoute();

const menuItemsLeft = computed<MenuItem[]>(() => [
  {
    label: i18n.t("home"),
    to: {
      name: "home",
    },
  },
]);

const menuItemsRight = computed<MenuItem[]>(() => {
  const items: MenuItem[] = [];
  const noReturnRoutes = [
    "home",
    "login",
    "logout",
    "forgot-password",
    "not-found",
    "onboarding",
  ] as RouteRecordName[];
  const currentReturnQuery: LocationQueryRaw = {};
  if (!noReturnRoutes.includes(route.name ?? "not-found")) {
    currentReturnQuery["return"] = route.fullPath;
  }

  if (auth.isSignedIn && auth.user.roleId !== null) {
    if (auth.user.roleId === Role.Actor) {
      if (auth.user.hasActiveEntry) {
        items.push({
          label: i18n.t("my-entry"),
          to: {
            name: "entry-view",
            params: {
              entrySlug: auth.user.activeSlug,
            },
          },
        });
      } else if (auth.user.hasDraftEntry) {
        items.push({
          label: i18n.t("my-draft-entry"),
          to: {
            name: "entry-editor",
            params: {
              entrySlug: auth.user.draftSlug,
            },
          },
        });
      }
    } else if (canEditAnyEntry(auth.user.roleId)) {
      items.push({
        label: i18n.t("review"),
        to: {
          name: "admin-dashboard",
        },
      });
    }

    items.push(
      {
        label: i18n.t("account"),
        to: {
          name: "account",
        },
      },
      {
        label: i18n.t("logout"),
        to: {
          name: "logout",
          query: currentReturnQuery,
        },
      }
    );
  } else {
    items.push({
      label: i18n.t("login"),
      to: {
        name: "login",
        query: currentReturnQuery,
      },
    });
  }

  return items;
});
</script>

<template>
  <nav>
    <div class="nav-wrapper">
      <div class="navbar-left">
        <router-link
          class="logos"
          :to="{ name: 'home' }"
          tabindex="-1"
          :title="$t('home')"
        >
          <div :class="['logo dlr']"></div>
          <div :class="['logo kara', $i18n.locale]"></div>
        </router-link>
        <router-link
          v-for="link in menuItemsLeft"
          :key="link.label"
          :to="link.to"
        >
          {{ link.label }}
        </router-link>
      </div>
      <div class="navbar-right">
        <router-link
          v-for="link in menuItemsRight"
          :key="link.label"
          :to="link.to"
        >
          {{ link.label }}
        </router-link>

        <language-selection />
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@use "sass:math";

@import "@/style/_content-wrapper.scss";

nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;

  height: calc(var(--navbar-height) + var(--navbar-backdrop-blur));
  margin-top: calc(-1 * var(--navbar-backdrop-blur));
  padding-top: var(--navbar-backdrop-blur);

  font-weight: bold;

  backdrop-filter: blur(var(--navbar-backdrop-blur));
  background: var(--navbar-background-color);
  border: 1px solid var(--navbar-border-color);

  box-shadow: var(--navbar-shadow);

  .nav-wrapper {
    @include ContentWrapper;

    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    [class^="navbar-"] {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      * {
        --padding-y: 0.5rem;

        display: flex;
        align-items: stretch;

        line-height: calc(var(--navbar-height) - 2 * var(--padding-y));
        text-align: center;
        padding: var(--padding-y) 1rem;
        text-decoration: none;
        font-weight: normal;

        &.router-link-active {
          text-decoration: underline;
        }
      }

      $heightFormula: calc(var(--navbar-height) - var(--navbar-padding));

      .kara {
        $enLogoAspectRatio: math.div(238, 241);
        $deLogoAspectRatio: math.div(332, 241);

        background-image: url(@/assets/logos/kara-en-dynamic.min.svg);
        width: calc($heightFormula * $enLogoAspectRatio);

        height: $heightFormula;
        align-self: center;

        background-repeat: no-repeat;
        background-size: contain;

        &.de-DE {
          background-image: url(@/assets/logos/kara-de-dynamic.min.svg);
          width: calc($heightFormula * $deLogoAspectRatio);
        }

        // check for Safari
        @supports (-webkit-hyphens: none) {
          // only provide a different url for safari, as it doesn't support dynamic SVGs
          @media (prefers-color-scheme: dark) {
            background-image: url(@/assets/logos/kara-en-inverted.min.svg);

            &.de {
              background-image: url(@/assets/logos/kara-de-inverted.min.svg);
            }
          }
        }
      }

      .dlr {
        $dlrLogoAspectRatio: math.div(90, 70);

        background-image: url(@/assets/logos/dlr-logo-dynamic.svg);
        width: calc($heightFormula * $dlrLogoAspectRatio);

        height: $heightFormula;
        align-self: center;

        background-repeat: no-repeat;
        background-size: contain;

        // check for Safari
        @supports (-webkit-hyphens: none) {
          // only provide a different url for safari, as it doesn't support dynamic SVGs
          @media (prefers-color-scheme: dark) {
            background-image: url(@/assets/logos/dlr-logo-inverted.svg);
          }
        }
      }
    }

    .navbar-left {
      .logos {
        gap: calc(var(--utilities-base) * 3);
      }
    }

    .navbar-right {
      margin-left: auto;
      justify-content: flex-end;
    }

    @media print {
      display: none;
    }
  }
}
</style>
