<script setup lang="ts">
import KaraMap from "@/components/KaraMap.vue";
import keyVisualLargeWebp from "@/assets/key-visual-7087x2025.webp";
import keyVisualMediumWebp from "@/assets/key-visual-3542x1012.webp";
import keyVisualSmallWebp from "@/assets/key-visual-1771x506.webp";
import keyVisualLargePng from "@/assets/key-visual-7087x2025.png";
import keyVisualMediumPng from "@/assets/key-visual-3542x1012.min.png";
import keyVisualSmallPng from "@/assets/key-visual-1771x506.min.png";
import { onMounted, reactive } from "vue";
import type MapPinDto from "@/dto/MapPinDto";
import api from "@/api";
import ApiError from "@/api/infrastructure/ApiError";
import toasts from "@/toasts";

const enableMapPins = import.meta.env.VITE_APP_ENABLE_MAP_PINS;

const pins = reactive<MapPinDto[]>([]);

onMounted(async () => {
  if (enableMapPins === "true") {
    try {
      const response = await api.home.mapPins();

      pins.splice(0); // clear
      pins.push(...response);
    } catch (e) {
      console.error(`Failed to load map pins: ${e}`);

      const errorKey = await ApiError.tryGetErrorKey(e);
      toasts.error(errorKey);
    }
  }
});
</script>

<template>
  <div>
    <div id="header-img">
      <picture>
        <source
          media="(min-width: 3000px)"
          :srcset="keyVisualLargeWebp"
          width="7087"
          height="2025"
          type="image/webp"
        />
        <source
          media="(min-width: 3000px)"
          :srcset="keyVisualLargePng"
          width="7087"
          height="2025"
          type="image/png"
        />
        <source
          media="(min-width: 1500px)"
          :srcset="keyVisualMediumWebp"
          width="3542"
          height="1012"
        />
        <source
          media="(min-width: 1500px)"
          :srcset="keyVisualMediumPng"
          width="3542"
          height="1012"
          type="image/png"
        />
        <source
          :srcset="keyVisualSmallWebp"
          width="1771"
          height="506"
          type="image/webp"
        />
        <source
          :srcset="keyVisualSmallPng"
          width="1771"
          height="506"
          type="image/png"
        />
        <img
          :src="keyVisualSmallWebp"
          alt="A picture of earth from space with a satellite in the foreground and images from the industry in its trail"
        />
      </picture>
    </div>

    <section>
      <div style="text-align: center">
        <h1 class="mt-3" style="color: var(--color-accent-heading)">
          {{ $t("welcome-to-the-german-space-catalog") }}
        </h1>
        <p style="text-align: justify; font-size: 115%">
          {{ $t("teaser-text") }}
          <router-link :to="{ name: 'about' }">
            {{ $t("continue-reading") }}
          </router-link>
        </p>
      </div>
    </section>

    <div id="map-wrapper">
      <kara-map :pins="pins" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "sass:math";
@import "@/style/content-wrapper";

section {
  @include ContentWrapper();

  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:first-of-type) {
    margin: 3rem auto;
  }
}

#header-img {
  background: black;
  width: 100%;
  max-height: max(20vh, 506px);
  aspect-ratio: 7087 / 2025;
  overflow: hidden;

  picture {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

#map-wrapper {
  width: calc(100vw - 20px);
  height: calc(min((515 / 381) * (100vw - 20px), 80vh));
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightskyblue;
}
</style>
