export const MapRoad = "roadmap";
export const MapSatellite = "satellite";
export const MapHybrid = "hybrid";
export const MapTerrain = "terrain";

export type MapType =
  | typeof MapRoad
  | typeof MapSatellite
  | typeof MapHybrid
  | typeof MapTerrain;
