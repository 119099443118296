<script setup lang="ts">
import useAuthStore from "@/stores/auth";
import { computed } from "vue";
import dlrLogoDe from "@/assets/logos/dlr-logo-de.svg";
import dlrLogoEn from "@/assets/logos/dlr-logo-en.svg";
import bmwkLogoDe from "@/assets/logos/bmwk_logo_de.svg";
import bmwkLogoEn from "@/assets/logos/bmwk_logo_en.svg";
import { tByLang } from "@/components/helpers/i18n";

const authStore = useAuthStore();

const dlrLogo = computed(() => tByLang(dlrLogoDe, dlrLogoEn));

const dlrLink = computed(() =>
  tByLang("https://www.dlr.de/rd", "https://www.dlr.de/rd/en")
);

const bmwkLogo = computed(() => tByLang(bmwkLogoDe, bmwkLogoEn));

const bmwkLink = computed(() =>
  tByLang("https://www.bmwi.de", "https://www.bmwi.de/en")
);
</script>

<template>
  <footer>
    <div id="footer-content-wrapper">
      <div id="footer-logos">
        <a :href="dlrLink" target="_blank">
          <span>{{ $t("on-behalf-of") }}:</span>
          <img
            :src="dlrLogo"
            :alt="$t('dlr-logo-alt')"
            style="height: 50px; width: auto"
          />
        </a>
        <a :href="bmwkLink" target="_blank">
          <span>{{ $t("sponsored-by") }}:</span>
          <img
            :src="bmwkLogo"
            :alt="$t('bmwk-logo-alt')"
            style="height: 70px; width: auto"
          />
        </a>
        <a href="https://www.trenz.de" target="_blank">
          <span>{{ $t("managed-by") }}:</span>
          <img
            src="@/assets/logos/trenz_logo.min.svg"
            alt="TRENZ GmbH"
            style="height: auto; width: 100%"
          />
        </a>
      </div>
      <div id="footer-columns">
        <section>
          <h4>{{ $t("sitemap") }}</h4>
          <router-link :to="{ name: 'home' }">{{ $t("home") }}</router-link>
          <router-link :to="{ name: 'about' }">{{ $t("about") }}</router-link>
          <router-link :to="{ name: 'contact' }">
            {{ $t("contact") }}
          </router-link>
          <router-link :to="{ name: 'help' }">
            {{ $t("help") }}
          </router-link>
          <template v-if="authStore.isSignedIn">
            <router-link :to="{ name: 'account' }">
              {{ $t("account") }}
            </router-link>
          </template>
          <template v-else>
            <router-link :to="{ name: 'login' }">{{ $t("login") }}</router-link>
          </template>
          <!--<router-link to="/search">{{ $t("search") }}</router-link>-->
          <!--<router-link to="/categories">{{ $t("categories") }}</router-link>-->
        </section>
        <section>
          <h4>{{ $t("legal") }}</h4>
          <router-link :to="{ name: 'imprint' }">
            {{ $t("imprint") }}
          </router-link>
          <router-link :to="{ name: 'privacy-policy' }">
            {{ $t("privacy-policy") }}
          </router-link>
          <router-link :to="{ name: 'general-terms-of-service' }">
            {{ $t("general-terms-of-service") }}
          </router-link>
          <router-link :to="{ name: 'terms-of-use' }">
            {{ $t("terms-of-use") }}
          </router-link>
          <span @click="$cc.show(true)" v-if="$cc.validConsent()" class="link">
            {{ $t("change-cookie-preferences") }}
          </span>
        </section>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@import "@/style/_variables.scss";

footer {
  z-index: -1;
  padding: 2rem 2rem 5rem;

  border-top: 1px solid var(--color-accent);

  display: flex;
  flex-direction: row;

  a {
    font-weight: normal;
    text-decoration: underline;
  }
}

#footer-logos {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  background: white;

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    align-items: center;
  }

  a {
    $padding: 1rem 2rem;
    padding: $padding;
    width: calc(max(20%, 100px));
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    @media (max-width: $mobile-breakpoint) {
      width: 75%;
    }

    span {
      display: block;
      font-size: 80%;
      text-align: center;
      margin-bottom: 5px;
      color: black;

      @media (max-width: $mobile-breakpoint) {
        font-size: 100%;
      }
    }
  }
}

#footer-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 3rem;

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }

  & > section {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    & > * {
      display: block;
    }
  }
}

#footer-content-wrapper {
  width: 100%;
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
}
</style>
