import HomeView from "@/views/HomeView.vue";
import Role from "@/stores/interfaces/Role";
import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/LogoutView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/ForgotPasswordView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/ResetPasswordView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/AccountView.vue"),
    meta: {
      minimumRole: Role.Actor,
      onlyGuest: false,
    },
  },
  {
    path: "/entry/:entrySlug(\\d+.*)",
    name: "entry",
    props: true,
    component: () => import("../views/EntryView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
    children: [
      {
        path: "",
        name: "entry-view",
        component: () => import("../views/entry/ActiveEntryView.vue"),
        meta: {
          minimumRole: null,
          onlyGuest: false,
        },
      },
      {
        path: "edit",
        name: "entry-editor",
        component: () => import("../views/entry/DraftEntryView.vue"),
        meta: {
          minimumRole: Role.Actor,
          onlyGuest: false,
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "admin-dashboard",
    component: () => import("../views/admin/AdminDashboardView.vue"),
    meta: {
      minimumRole: Role.EditorExternal,
      onlyGuest: false,
    },
  },
  {
    path: "/admin/review/:entrySlug(\\d+.*)",
    name: "admin-review",
    props: true,
    component: () => import("../views/admin/AdminEntryReviewView.vue"),
    meta: {
      minimumRole: Role.EditorExternal,
      onlyGuest: false,
    },
  },
  {
    path: "/welcome",
    name: "onboarding",
    component: () => import("../views/OnboardingView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: true,
    },
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import("../views/ImprintView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/general-terms-of-service",
    name: "general-terms-of-service",
    component: () => import("../views/GeneralTermsOfServiceView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: () => import("../views/TermsOfUseView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/PrivacyPolicyView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/HelpView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },

  // This route must always be last since it is the catchall route.
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFoundView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  },
];

if (import.meta.env.VITE_PREVIEW_ENABLED) {
  routes.push({
    path: "/guard",
    name: "preview-login",
    component: () => import("../views/PreviewLoginView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  });
}

if (import.meta.env.MODE.toLowerCase() !== "production") {
  routes.push({
    path: "/styletest",
    name: "styletest",
    component: () => import("../views/StyleTestView.vue"),
    meta: {
      minimumRole: null,
      onlyGuest: false,
    },
  });
}

export default routes;
