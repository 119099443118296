export default {
  sleep: (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),

  async waitUntil(condition: () => boolean): Promise<void> {
    while (!condition()) {
      await this.sleep(100);
    }
  },

  debounce: (func: TimerHandler, timeout = 300) => {
    let timer: number | undefined;

    return () => {
      clearTimeout(timer);

      timer = setTimeout(func, timeout);
    };
  },
};
